import { useState } from "react";
import Image from "next/image";
import { Modal } from "@/ui/Modal";
import { Spinner } from "@/ui/Spinner";
import { Api } from "@/app/api";
import { ChevronDoubleRightIcon } from "@heroicons/react/outline";
import { ArrowCircleRightIcon, CheckIcon } from "@heroicons/react/solid";
import { Field, Formik, Form } from "formik";
import { useMutation } from "react-query";

export interface LoginCredentials {
  email: string;
  password: string;
}

interface AccessRequest {
  name: string;
  email: string;
}

interface LoginComponentProps {
  doLogin(creds: LoginCredentials): Promise<void> | void;
}

export function LoginComponent({ doLogin }: LoginComponentProps) {
  const requestAccessMutation = useMutation(({ name, email }: AccessRequest) =>
    Api.requestAccess(name, email),
  );
  const requestCredentialsMutation = useMutation((askEmail: string) =>
    Api.requestCredentials(askEmail),
  );
  const loginMutation = useMutation(async (creds: LoginCredentials) =>
    doLogin(creds),
  );

  const [isModalShowing, setIsModalShowing] = useState(false);
  const [isRequestingAccess, setIsRequestingAccess] = useState(false);
  const [accessDetailsValid, setAccessDetailsValid] = useState(true);

  return (
    <>
      <div
        className={`
        -my-3 flex justify-between
      `}
      >
        <div className="scale-aiea -ml-10 transform">
          <img
            className="h-36 w-auto"
            src="/images/logos/SAID Logo (Dark).png"
            // width="300px"
            // height="140px"
          />
        </div>

        <div className="scale-aiea -mr-32 flex transform items-center justify-center">
          <Formik<LoginCredentials>
            initialValues={{ email: "", password: "" }}
            onSubmit={({ email, password }) =>
              loginMutation.mutate({ email, password })
            }
          >
            <Form>
              <div className=" flex items-center space-x-4 pr-12">
                {loginMutation.isError && (
                  <p className="mr-2 text-center text-sm text-red-800">
                    Incorrect username or password
                  </p>
                )}
                <Field
                  name="email"
                  className="text-md bg-blue-100 px-3 py-2.5 font-semibold text-gray-800 placeholder-gray-500 focus:bg-blue-200 focus:outline-none"
                  placeholder="Email"
                  type="email"
                />
                <Field
                  name="password"
                  className="text-md bg-blue-100 px-3 py-2.5 font-semibold text-gray-800 placeholder-gray-500 focus:bg-blue-200 focus:outline-none"
                  placeholder="Password"
                  type="password"
                />
                <button
                  disabled={loginMutation.isLoading}
                  type="submit"
                  className={`bg-denim-900 w-56 items-center rounded px-6 py-2.5 text-lg font-semibold text-white focus:outline-none ${
                    loginMutation.isLoading
                      ? ""
                      : "hover:bg-aiealight-600 hover:text-denim-900"
                  } transition-all duration-300 ease-out`}
                >
                  {loginMutation.isLoading ? (
                    <div className="mx-auto  flex items-center space-x-2">
                      <Spinner className="ml-6 h-6" />
                      <p className="whitespace-nowrap text-center">
                        Logging In
                      </p>
                    </div>
                  ) : (
                    <p>Log in</p>
                  )}
                </button>
                {/* <div className="flex-col items-center space-y-2 pr-12">
                  <div className="mt-6 flex space-x-3">
                   
                     <div className="flex items-center">
                      <p className="text-sm text-center mx-auto">OR</p>
                    </div> 
                    <div className="flex items-center space-x-10">
                       <button
                        onClick={() => setIsRequestingAccess(true)}
                        disabled={isRequestingAccess}
                        className={`bg-turqoise-blue w-56 py-2 text-white font-semibold text-lg rounded px-6 items-center focus:outline-none ${isRequestingAccess
                          ? ""
                          : "hover:bg-aiealight-600 hover:text-denim-900"
                          } transition-all ease-out duration-300`}
                      >
                        <p>Request Access</p>
                      </button> *
                       <a
                        className="hover:cursor-pointer hover:text-denim-800 pl-12"
                        href="https://www.merlynn-ai.com/governancepolicies"
                        target="blank"
                      >
                        Privacy Policy
                      </a> 
                    </div>
                  </div>
                   <div className="flex ml-6 mt-2 items-center space-x-0.5 text-gray-700 hover:text-turqoise-blue hover:cursor-pointer transition-all ease-out duration-300 hover:underline">
                    <a
                      onClick={() => setIsModalShowing(true)}
                      className="text-center text-xs"
                    >
                      Forgot username or password
                    </a>
                    <ChevronDoubleRightIcon className="h-3 text-opacity-80 bg-opacity-80" />
                  </div> 
                </div> */}
              </div>
            </Form>
          </Formik>
        </div>
      </div>

      <div className="scale-aiea transform">
        <Modal open={isModalShowing}>
          <div className="max-h-200 font-aiea flex flex-col items-center rounded-md border-4 border-gray-200 transition-all duration-300 ease-in-out">
            <p className="text-denim-900 px-8 py-6 text-center text-2xl font-semibold">
              Forgot your password?
            </p>
            <p className="w-132 pb-12 pl-12 pr-8 pt-6 text-left text-lg text-gray-800">
              Please fill in the email that you used to register. You will then
              be contacted with next steps to recover your password.
            </p>
            <div className="flex flex-col">
              {(requestCredentialsMutation.isSuccess ||
                requestCredentialsMutation.isError) && (
                <p
                  className={`mx-8 mb-2 text-left text-xs ${
                    requestCredentialsMutation.isSuccess
                      ? "text-green-600"
                      : "text-red-600"
                  } transition-all duration-300 ease-in-out`}
                >
                  {requestCredentialsMutation.isSuccess
                    ? "Email sent successfully"
                    : "Something went wrong. Please try again in a few minutes"}
                </p>
              )}
              <div className="w-101 mx-8 mb-8 flex items-center space-x-2">
                <Formik
                  initialValues={{ askEmail: "" }}
                  onSubmit={({ askEmail }) =>
                    requestCredentialsMutation.mutate(askEmail)
                  }
                >
                  <Form className="flex flex-1">
                    <div className="flex flex-1 items-center gap-x-3">
                      <Field
                        className="text-md w-full bg-blue-100 px-3 py-2 font-semibold text-gray-800 placeholder-gray-500 focus:bg-blue-200 focus:outline-none"
                        placeholder="Email"
                        type="email"
                        name="askEmail"
                      />
                      {requestCredentialsMutation.isLoading ? (
                        <Spinner className="text-turqoise-blue h-8" />
                      ) : requestCredentialsMutation.isSuccess ? (
                        <CheckIcon className="text-turqoise-blue hover:text-turqoise-blue h-8 transition-all duration-300 ease-out" />
                      ) : (
                        <button type="submit">
                          <ArrowCircleRightIcon className="text-denim-900 hover:text-turqoise-blue h-8 transition-all duration-300 ease-out" />
                        </button>
                      )}
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
            <div className="hover:text-turqoise-blue mb-6 flex items-center space-x-0.5 text-gray-700 transition-all duration-300 ease-out hover:cursor-pointer hover:underline">
              <a
                onClick={() => setIsModalShowing(false)}
                className="text-center text-xs"
              >
                Return to log in
              </a>
              <ChevronDoubleRightIcon className="h-3 bg-opacity-80 text-opacity-80" />
            </div>
          </div>
        </Modal>

        <Modal open={isRequestingAccess}>
          <div className="max-h-200 font-aiea flex flex-col items-center rounded-md border-4 border-gray-200 transition-all duration-300 ease-in-out">
            <p className="text-denim-900 px-8 py-6 text-center text-2xl font-semibold">
              Would you like to access AIEA?
            </p>
            <p className="w-132 pb-12 pl-12 pr-8 pt-6 text-left text-lg text-gray-800">
              Please provide your name and email address. You will then be
              contacted to continue registration.
            </p>
            <div className="flex flex-col">
              {!accessDetailsValid && (
                <p
                  className={`mx-8 mb-2 text-left text-xs text-red-600 transition-all duration-300 ease-in-out`}
                >
                  A name and valid contact email address are required
                </p>
              )}
              {(requestAccessMutation.isSuccess ||
                requestAccessMutation.isError) && (
                <p
                  className={`mx-8 mb-2 text-left text-xs ${
                    requestAccessMutation.isSuccess
                      ? "text-green-600"
                      : "text-red-600"
                  } transition-all duration-300 ease-in-out`}
                >
                  {requestAccessMutation.isSuccess
                    ? "You have successfully requested access to AIEA"
                    : "Something went wrong. Please try again in a few minutes"}
                </p>
              )}
              <div className="w-101 mx-8 mb-8 flex items-center space-x-2">
                <Formik<AccessRequest>
                  initialValues={{ name: "", email: "" }}
                  onSubmit={({ name, email }, { resetForm }) => {
                    if (
                      name.length > 0 &&
                      email.includes("@") &&
                      email.includes(".")
                    ) {
                      setAccessDetailsValid(true);
                      requestAccessMutation.mutate(
                        { name: name, email: email },
                        {
                          onSuccess: () => {
                            resetForm();
                          },
                        },
                      );
                    } else {
                      setAccessDetailsValid(false);
                    }
                  }}
                >
                  <Form className="flex flex-1">
                    <div className="flex flex-1 items-center gap-x-3">
                      <div className="flex-col space-y-2">
                        <Field
                          className="text-md w-full bg-blue-100 px-3 py-2 font-semibold text-gray-800 placeholder-gray-500 focus:bg-blue-200 focus:outline-none"
                          placeholder="Full Name"
                          type="name"
                          name="name"
                        />
                        <Field
                          className="text-md w-full bg-blue-100 px-3 py-2 font-semibold text-gray-800 placeholder-gray-500 focus:bg-blue-200 focus:outline-none"
                          placeholder="Email"
                          type="email"
                          name="email"
                        />
                      </div>
                      {requestAccessMutation.isLoading ? (
                        <Spinner className="text-turqoise-blue h-8" />
                      ) : (
                        // requestAccessMutation.isSuccess ? (
                        //   <CheckIcon className="h-8 text-turqoise-blue hover:text-turqoise-blue transition-all ease-out duration-300" />
                        <button type="submit">
                          <ArrowCircleRightIcon className="text-denim-900 hover:text-turqoise-blue h-8 transition-all duration-300 ease-out" />
                        </button>
                      )}
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
            <div className="hover:text-turqoise-blue mb-6 flex items-center space-x-0.5 text-gray-700 transition-all duration-300 ease-out hover:cursor-pointer hover:underline">
              <a
                onClick={() => setIsRequestingAccess(false)}
                className="text-center text-xs"
              >
                Return to log in
              </a>
              <ChevronDoubleRightIcon className="h-3 bg-opacity-80 text-opacity-80" />
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
}
