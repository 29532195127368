import { Fragment, useState, useEffect } from "react";
import { useRouter } from "next/dist/client/router";
import Head from "next/head";
import { Modal } from "@/ui/Modal";
import { Spinner } from "@/ui/Spinner";
import { Api } from "@/app/api";
import { AIEA_GUEST_DOMAIN } from "@/app/config";
import { useAppDispatch } from "@/app/hooks";
import { LoginComponent, LoginCredentials } from "@/components/LoginComponent";
import { AIEBadge } from "@/components/elements/AIEBadge";
import { LocalVideo } from "@/components/elements/LocalVideo";
import { Transition } from "@headlessui/react";
import {
  ArrowCircleRightIcon,
  ChevronDoubleRightIcon,
} from "@heroicons/react/solid";
import * as Sentry from "@sentry/nextjs";
import { setAccountCreationState } from "features/accountCreation/accountCreationSlice";
import { Field, Formik, Form } from "formik";
import { isMobile } from "react-device-detect";
import { useMutation } from "react-query";

//#region INTERFACES
interface AccessRequest {
  name: string;
  email: string;
}
//#endregion

//#region PUBLIC API
export default function Login() {
  const router = useRouter();

  const dispatch = useAppDispatch();

  const requestAccessMutation = useMutation(({ name, email }: AccessRequest) =>
    Api.requestAccess(name, email),
  );

  const [hasViewedLanding, setHasViewedLanding] = useState(false);
  const [isModalShowing, setIsModalShowing] = useState(false);
  const [isRequestingAccess, setIsRequestingAccess] = useState(false);
  const [accessDetailsValid, setAccessDetailsValid] = useState(true);

  const doLogin = async ({ email, password }: LoginCredentials) => {
    try {
      const user = await Api.login(email, password);
      Sentry.setUser({ email: user.email });
      await router.push("/");
    } catch (e) {
      const up2tomToken = await Api.getUp2TomTokenForCredentials(
        email,
        password,
      );
      if (up2tomToken.isSome()) {
        dispatch(
          setAccountCreationState({
            email,
            password,
            token: up2tomToken.get(),
          }),
        );
        await router.push(`/terms-and-conditions`);
      } else {
        throw e;
      }
    }
  };

  const [content, setContent] = useState<any>();
  useEffect(() => {
    if (isMobile) {
      setContent(
        <>
          <div className="font-SAID scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-500 scrollbar-track-rounded scrollbar-thumb-rounded h-screen w-screen flex-col items-center space-y-4 divide-y divide-gray-400 overflow-y-auto px-6 pb-6 pt-4">
            <img
              src="/images/logos/SAID Logo (Dark).png"
              className="h-38 mx-auto"
            />

            <div className="text-aieadark-40 flex-col items-center bg-white pt-4">
              <h1 className="text-primary bg-primary text-center text-2xl font-bold">
                Swift Artificial Intelligence Development
              </h1>
              <h2 className="text-md text-aiealight-600 mt-2 text-center font-semibold">
                Democratizing Access to Human Expertise
              </h2>

              <button
                onClick={() => setIsRequestingAccess(true)}
                disabled={isRequestingAccess}
                className={`bg-turqoise-blue mb-4 mt-10 w-full items-center rounded px-6 py-2 text-lg font-semibold text-white focus:outline-none ${
                  isRequestingAccess
                    ? ""
                    : "hover:bg-aiealight-600 hover:text-denim-900"
                } transition-all duration-300 ease-out`}
              >
                <p>Request Access</p>
              </button>

              <div className="w-full flex-col">
                <div className="border-aiealight-600 group mr-8 mt-8 flex w-full items-center justify-between border-2 px-4 py-2 focus:outline-none">
                  <p className="text-lg font-semibold">
                    What is a Decisioning Digital Twin?
                  </p>
                </div>
                <div className="text-denim-800 mx-1 mt-2">
                  <p className="mt-4 text-sm">
                    An AI Model (or series of models) that responds to a problem
                    or question exactly as its human counterpart would, only in
                    real-time.
                  </p>
                </div>

                <div className="border-aiealight-600 group mr-8 mt-8 w-full select-none border-2 px-4 py-2 focus:outline-none">
                  <div className="h-full w-full flex-col">
                    <div className="flex w-full items-center justify-between">
                      <p className="text-lg font-semibold">
                        {"Why Digital Twins?"}
                      </p>
                    </div>
                  </div>
                </div>
                <p className="mt-4 text-sm">
                  Human experts can physically only work in one place at one
                  time, for approximately 8-12 hours a day. Their Digital Twins
                  have none of these physical capacity constraints. The AIEA
                  enables Digital Twins to work in multiple locations, 24/7, 365
                  days a year.
                </p>
                <p className="mt-6 text-sm font-semibold">
                  Via SAID, organizations access and employ (or deploy) this
                  unlimited source of expertise in 3 steps:
                </p>
                <p className="mt-2 text-sm">
                  <span className="mr-2 font-bold">&#8226;</span> Search for
                  required expertise (anywhere in the world).
                </p>
                <p className="mt-1 text-sm">
                  <span className="mr-2 font-bold">&#8226;</span> Engage the
                  expert (via the platform application form).
                </p>
                <p className="mt-1 text-sm">
                  <span className="mr-2 font-bold">&#8226;</span> Download
                  Digital Twins into your environment.
                </p>
              </div>

              <div className="bg-denim-900 mt-12 flex-col space-y-2 rounded-lg p-2 text-center text-xs font-semibold text-white">
                <p>SAID is not available on this device yet!</p>
                <p>Give it a try on your desktop computer instead.</p>
              </div>

              <a
                className="hover:cursor-pointer"
                href="https://www.merlynn-ai.com/"
                target="blank"
              >
                <img
                  src="/images/logos/powered-by-mit-logo.png"
                  className="mx-auto mt-4 h-32"
                />
              </a>
            </div>

            <Modal open={isRequestingAccess}>
              <div className="flex flex-col items-center rounded-md border-4 border-gray-200 transition-all duration-300 ease-in-out">
                <p className="text-denim-900 px-8 py-6 text-center text-2xl font-semibold">
                  Would you like to access AIEA?
                </p>
                <p className="text-md pb-12 pl-12 pr-8 pt-6 text-center text-gray-800">
                  Please provide your name and email address. You will then be
                  contacted to continue registration.
                </p>
                <div className="flex flex-col">
                  {!accessDetailsValid && (
                    <p
                      className={`mx-8 mb-2 text-left text-xs text-red-600 transition-all duration-300 ease-in-out`}
                    >
                      A name and valid contact email address are required
                    </p>
                  )}
                  {(requestAccessMutation.isSuccess ||
                    requestAccessMutation.isError) && (
                    <p
                      className={`mx-8 mb-2 text-left text-xs ${
                        requestAccessMutation.isSuccess
                          ? "text-green-600"
                          : "text-red-600"
                      } transition-all duration-300 ease-in-out`}
                    >
                      {requestAccessMutation.isSuccess
                        ? "You have successfully requested access to AIEA"
                        : "Something went wrong. Please try again in a few minutes"}
                    </p>
                  )}
                  <div className="mx-8 mb-8 flex items-center space-x-2">
                    <Formik<AccessRequest>
                      initialValues={{ name: "", email: "" }}
                      onSubmit={({ name, email }, { resetForm }) => {
                        if (
                          name.length > 0 &&
                          email.includes("@") &&
                          email.includes(".")
                        ) {
                          setAccessDetailsValid(true);
                          requestAccessMutation.mutate(
                            { name: name, email: email },
                            {
                              onSuccess: () => {
                                resetForm();
                              },
                            },
                          );
                        } else {
                          setAccessDetailsValid(false);
                        }
                      }}
                    >
                      <Form className="flex flex-1" autoComplete="off">
                        <div className="flex flex-1 items-center gap-x-3">
                          <div className="flex-col space-y-2">
                            <Field
                              autoComplete="off"
                              className="text-md w-full bg-blue-100 px-3 py-2 font-semibold text-gray-800 placeholder-gray-500 focus:bg-blue-200 focus:outline-none"
                              placeholder="Full Name"
                              type="name"
                              name="name"
                            />
                            <Field
                              autoComplete="off"
                              className="text-md w-full bg-blue-100 px-3 py-2 font-semibold text-gray-800 placeholder-gray-500 focus:bg-blue-200 focus:outline-none"
                              placeholder="Email"
                              type="email"
                              name="email"
                            />
                          </div>
                          {requestAccessMutation.isLoading ? (
                            <Spinner className="text-turqoise-blue h-8" />
                          ) : (
                            // requestAccessMutation.isSuccess ? (
                            //   <CheckIcon className="h-8 transition-all duration-300 ease-out text-turqoise-blue hover:text-turqoise-blue" />
                            <button type="submit">
                              <ArrowCircleRightIcon className="text-denim-900 hover:text-turqoise-blue h-8 transition-all duration-300 ease-out focus:border-0 focus:outline-none" />
                            </button>
                          )}
                        </div>
                      </Form>
                    </Formik>
                  </div>
                </div>
                <div className="hover:text-turqoise-blue mb-6 flex items-center space-x-0.5 text-gray-700 transition-all duration-300 ease-out hover:cursor-pointer hover:underline">
                  <a
                    onClick={() => setIsRequestingAccess(false)}
                    className="text-center text-sm"
                  >
                    Return
                  </a>
                  <ChevronDoubleRightIcon className="h-3 bg-opacity-80 text-opacity-80" />
                </div>
              </div>
            </Modal>
          </div>
        </>,
      );
    } else if (hasViewedLanding) {
      setContent(
        <>
          <div className="divide divide-turqoise-blue h-screen w-screen select-none flex-col divide-y px-8">
            <div className="z-100">
              <LoginComponent doLogin={doLogin} />
            </div>
            <div className="font-SAID w-full flex-col">
              <div className="text-aieadark-400 scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-500 scrollbar-track-rounded scrollbar-thumb-rounded z-50 mt-8 w-full flex-col items-center">
                <div className="h-full w-full flex-col items-center bg-white pr-4">
                  <h1 className="text-3xl font-bold">
                    Swift Artificial Intelligence Development
                  </h1>
                  <h2 className="text-aiealight-600 text-lg font-semibold">
                    A safe and secure solutions platform where members
                    collaborate through shared decision intelligence.
                  </h2>
                  <p className="text-denim-900 mt-6 text-sm font-semibold">
                    Solving the skills crisis - one
                    <span className="text-denim-800 mx-1 font-bold">
                      Decisioning Digital Twin
                    </span>
                    at a time
                  </p>

                  <div className="w-full flex-col">
                    <div className="group mr-8 mt-6 flex w-full items-center justify-between py-2 focus:outline-none">
                      <p className="text-xl font-semibold">
                        What is a Decisioning Digital Twin?
                      </p>
                    </div>
                    <div className="text-denim-800 mx-1 mt-2">
                      <p className="mt-2">
                        An AI Model (or series of models) that responds to a
                        problem or question exactly as its human counterpart
                        would, only in real-time.
                      </p>
                    </div>

                    <div className="group mr-8 mt-4 w-full select-none py-2 focus:outline-none">
                      <div className="h-full w-full flex-col">
                        <div className="flex w-full items-center justify-between">
                          <p className="text-xl font-semibold">
                            {"Why Digital Twins?"}
                          </p>
                        </div>
                      </div>
                    </div>
                    <p className="mt-2">
                      Human experts can physically only work in one place at one
                      time, for approximately 8-12 hours a day. Their Digital
                      Twins have none of these physical capacity constraints.
                      The SAID enables Digital Twins to work in multiple
                      locations, 24/7, 365 days a year.
                    </p>
                    <p className="mt-6 font-semibold">
                      Via SAID, organizations access and employ (or deploy) this
                      unlimited source of expertise in 3 steps:
                    </p>
                    <p className="mt-2">
                      <span className="mr-2 font-bold">&#8226;</span> Search for
                      required expertise (anywhere in the world).
                    </p>
                    <p className="mt-1">
                      <span className="mr-2 font-bold">&#8226;</span> Engage the
                      expert (via the platform application form).
                    </p>
                    <p className="mt-1">
                      <span className="mr-2 font-bold">&#8226;</span> Download
                      Digital Twins into your environment.
                    </p>
                  </div>
                  <div className="mt-10 flex items-center space-x-10">
                    <a
                      className={`bg-turqoise-blue w-1/2 items-center rounded px-6 py-4 text-center text-lg font-semibold text-white shadow-lg focus:outline-none ${
                        isRequestingAccess
                          ? ""
                          : "hover:bg-aiealight-600 hover:text-denim-900 hover:animate-pulse"
                      } transition-all duration-300 ease-out`}
                      href={AIEA_GUEST_DOMAIN}
                    >
                      <p>Explore The Agency</p>
                      {/* <p className="text-sm font-light">Search for expertise</p> */}
                    </a>
                    {/* <button
                      onClick={() => setIsRequestingAccess(true)}
                      disabled={isRequestingAccess}
                      className={`bg-turqoise-blue w-full py-2 text-white font-semibold text-lg rounded px-6 items-center focus:outline-none ${isRequestingAccess
                        ? ""
                        : "hover:bg-aiealight-600 hover:text-denim-900"
                        } transition-all ease-out duration-300`}
                    >
                      <p>Join The Agency</p>
                      <p className="text-sm font-light">Sell your expertise</p>
                    </button> */}
                  </div>
                </div>
              </div>

              <div className="flex w-full items-center justify-between">
                <div className="font-SAID scale-SAID bottom-10 left-10 ml-6 origin-bottom-left transform text-white text-opacity-50">
                  <div className="flex-col items-center">
                    <div className="flex items-center">
                      <img
                        src={"/images/logos/SAID Logo (Dark).png"}
                        className="h-14"
                      />
                      {/* <p className={`mt-4 font-semibold ${isColored ? "text-denim-900 text-opacity-50" : "text-white"}`}>beta</p> */}
                    </div>
                  </div>
                </div>

                <a
                  className="hover:cursor-pointer"
                  href="https://www.merlynn-ai.com/"
                  target="blank"
                >
                  <img
                    src="/images/logos/powered-by-mit-logo.png"
                    className="font-SAID scale-SAID h-32 origin-bottom-left text-white text-opacity-50"
                  />
                </a>
              </div>
            </div>
          </div>

          <div className="scale-SAID transform">
            <Modal open={isModalShowing}>
              <div className="max-h-200 font-SAID flex flex-col items-center rounded-md border-4 border-gray-200 pb-8 transition-all duration-300 ease-in-out">
                <p className="text-denim-900 px-8 py-6 text-center text-2xl font-semibold">
                  Login Required
                </p>
                <p className="w-132 px-12 pb-12 pt-6 text-center text-lg text-gray-800">
                  You need to be logged in to access our library of Digital
                  Twins.
                </p>
                <button
                  onClick={() => setIsModalShowing(false)}
                  className="bg-denim-900 hover:bg-aiealight-600 hover:text-denim-900 w-56 items-center rounded px-6 py-2 text-lg font-semibold text-white transition-all duration-300 ease-out focus:outline-none"
                >
                  OK
                </button>
              </div>
            </Modal>
            <Modal open={isRequestingAccess}>
              <div className="max-h-200 font-SAID flex flex-col items-center rounded-md border-4 border-gray-200 transition-all duration-300 ease-in-out">
                <p className="text-denim-900 px-8 py-6 text-center text-2xl font-semibold">
                  Would you like to access AIEA?
                </p>
                <p className="w-132 pb-12 pl-12 pr-8 pt-6 text-left text-lg text-gray-800">
                  Please provide your name and email address. You will then be
                  contacted to continue registration.
                </p>
                <div className="flex flex-col">
                  {!accessDetailsValid && (
                    <p
                      className={`mx-8 mb-2 text-left text-xs text-red-600 transition-all duration-300 ease-in-out`}
                    >
                      A name and valid contact email address are required
                    </p>
                  )}
                  {(requestAccessMutation.isSuccess ||
                    requestAccessMutation.isError) && (
                    <p
                      className={`mx-8 mb-2 text-left text-xs ${
                        requestAccessMutation.isSuccess
                          ? "text-green-600"
                          : "text-red-600"
                      } transition-all duration-300 ease-in-out`}
                    >
                      {requestAccessMutation.isSuccess
                        ? "You have successfully requested access to AIEA"
                        : "Something went wrong. Please try again in a few minutes"}
                    </p>
                  )}
                  <div className="w-101 mx-8 mb-8 flex items-center space-x-2">
                    <Formik<AccessRequest>
                      initialValues={{ name: "", email: "" }}
                      onSubmit={({ name, email }, { resetForm }) => {
                        if (
                          name.length > 0 &&
                          email.includes("@") &&
                          email.includes(".")
                        ) {
                          setAccessDetailsValid(true);
                          requestAccessMutation.mutate(
                            { name: name, email: email },
                            {
                              onSuccess: () => {
                                resetForm();
                              },
                            },
                          );
                        } else {
                          setAccessDetailsValid(false);
                        }
                      }}
                    >
                      <Form className="flex flex-1">
                        <div className="flex flex-1 items-center gap-x-3">
                          <div className="flex-col space-y-2">
                            <Field
                              className="text-md w-full bg-blue-100 px-3 py-2 font-semibold text-gray-800 placeholder-gray-500 focus:bg-blue-200 focus:outline-none"
                              placeholder="Full Name"
                              type="name"
                              name="name"
                            />
                            <Field
                              className="text-md w-full bg-blue-100 px-3 py-2 font-semibold text-gray-800 placeholder-gray-500 focus:bg-blue-200 focus:outline-none"
                              placeholder="Email"
                              type="email"
                              name="email"
                            />
                          </div>
                          {requestAccessMutation.isLoading ? (
                            <Spinner className="text-turqoise-blue h-8" />
                          ) : (
                            // requestAccessMutation.isSuccess ? (
                            //   <CheckIcon className="h-8 transition-all duration-300 ease-out text-turqoise-blue hover:text-turqoise-blue" />
                            <button type="submit">
                              <ArrowCircleRightIcon className="text-denim-900 hover:text-turqoise-blue h-8 transition-all duration-300 ease-out" />
                            </button>
                          )}
                        </div>
                      </Form>
                    </Formik>
                  </div>
                </div>
                <div className="hover:text-turqoise-blue mb-6 flex items-center space-x-0.5 text-gray-700 transition-all duration-300 ease-out hover:cursor-pointer hover:underline">
                  <a
                    onClick={() => setIsRequestingAccess(false)}
                    className="text-center text-xs"
                  >
                    Return to log in
                  </a>
                  <ChevronDoubleRightIcon className="h-3 bg-opacity-80 text-opacity-80" />
                </div>
              </div>
            </Modal>
          </div>
        </>,
      );
    } else {
      setContent(
        <>
          <div className="font-SAID z-0 flex min-h-screen bg-gradient-to-t from-zinc-900 to-zinc-800 bg-auto bg-center bg-no-repeat">
            <Transition.Root show={!hasViewedLanding}>
              <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
              >
                <div className="flex h-screen w-screen origin-center flex-col items-center align-middle">
                  <img
                    src="/images/logos/said-logo.png"
                    className="my-6 w-screen origin-center text-white text-opacity-50"
                    alt="SAID Logo"
                  />
                  <div className="origin-center items-center justify-center align-middle">
                    <button
                      onClick={() => setHasViewedLanding(true)}
                      className="hover:bg-swiftturqoise w-56 rounded border-2 border-zinc-900 bg-zinc-300 px-6 py-2 text-lg font-semibold text-zinc-800 transition-all duration-300 ease-out hover:border-white hover:text-zinc-100 focus:outline-none"
                    >
                      Enter
                    </button>
                  </div>
                </div>
              </Transition.Child>
            </Transition.Root>
          </div>
        </>,
      );
    }
  }, [
    hasViewedLanding,
    isModalShowing,
    isRequestingAccess,
    requestAccessMutation.data,
  ]);

  return (
    <>
      <Head>
        <title>AI Employment Agency | Login</title>
      </Head>

      <div>{content}</div>
    </>
  );
}
//#endregion
